import { bookingURL } from "../helpers/paths";
import { images } from "../source";

export const accommodationData = [
  {
    id: 1,
    img: images.sharing01.image,
    mbImg: images.mbSharing01.image,
    alt: images.sharing01.alt,
    title: "4 Share room",
    // title: "4",
    // boldTitle: "Sharing",
    description:
    "Fee starting at <strong>Rs. 51,786/-</strong> (Per month all Inclusive)",
      // "Ideal for those who love community living, our 4 sharing rooms are perfect for making lifelong friends.",
    highlights: [
      {
        icon: images.acAcIcon.image,
        title:
          "Centrally air-conditioned rooms with in-room temperature control",
      },
      {
        icon: images.acHousekeepingIcon.image,
        title: "Pantry kitchenette area on every floor with all provisions",
      },
      {
        icon: images.acMedicineIcon.image,
        title: "Housekeeping services",
      },
      {
        icon: images.acAccessIcon.image,
        title: "Facial recognition to enter accommodation floors",
      },
      // {
      //   icon: images.acMedicineIcon.image,
      //   title: "Medical & General accident insurance",
      // },
    ],
    link: bookingURL,
  },
  {
    id: 2,
    img: images.sharing02.image,
    mbImg: images.mbSharing02.image,
    alt: images.sharing02.alt,
    title: "3 Share room",
    // title: "3",
    // boldTitle: "Sharing",
    description:
    "Fee starting at <strong>Rs. 57,666/-</strong> (Per month all Inclusive)",
      // "A slightly more private option, the 3 sharing rooms provide a balance between social interaction and personal space.",
      highlights: [
        {
          icon: images.acAcIcon.image,
          title:
            "Centrally air-conditioned rooms with in-room temperature control",
        },
        {
          icon: images.acHousekeepingIcon.image,
          title: "Pantry kitchenette area on every floor with all provisions",
        },
        // },
        {
          icon: images.acMedicineIcon.image,
          title: "Housekeeping services",
        },
        {
          icon: images.acAccessIcon.image,
          title: "Facial recognition to enter accommodation floors",
        },
        // {
        //   icon: images.acMedicineIcon.image,
        //   title: "Medical & General accident insurance",
        // },
      ],
      link: bookingURL,
  },
  {
    id: 3,
    img: images.sharing03.image,
    mbImg: images.mbSharing03.image,
    alt: images.sharing03.alt,
    title: "Twin Share room",
    // title: "2",
    // boldTitle: "Sharing",
    description:
    "Fee starting at <strong>Rs. 69,426/-</strong> (Per month all Inclusive)",
      // "For those who prefer a more intimate setting, our 2 sharing rooms offer comfort with fewer roommates.",
      highlights: [
        {
          icon: images.acAcIcon.image,
          title:
            "Centrally air-conditioned rooms with in-room temperature control",
        },
        {
          icon: images.acHousekeepingIcon.image,
          title: "Pantry kitchenette area on every floor with all provisions",
        },
        {
          icon: images.acMedicineIcon.image,
          title: "Housekeeping services",
        },
        {
          icon: images.acAccessIcon.image,
          title: "Facial recognition to enter accommodation floors",
        },
        // {
        //   icon: images.acMedicineIcon.image,
        //   title: "Medical & General accident insurance",
        // },
      ],
      link: bookingURL,
  },
  // {
  //   id: 4,
  //   img: images.sharing04.image,
  //   mbImg: images.mbSharing04.image,
  //   alt: images.sharing04.alt,
  //   title: "2 Sharing with ",
  //   boldTitle: "Ensuite Washroom",
  //   description:
  //   "",
  //     // "Combining privacy and convenience, these rooms come with a private washroom, providing the ultimate comfort.",
  //   link: bookingURL,
  // },
];

export const restRoomData = [
  {
    id: 1,
    img: images.restRoom00.image,
    alt: images.restRoom00.alt,
    list: "Separate washrooms for men and women on their respective floors",
    imgClass: "separate_img",
  },
  {
    id: 2,
    img: images.restRoom01.image,
    alt: images.restRoom01.alt,
    list: "Large areas with separate dry and wet areas",
  },
  {
    id: 3,
    img: images.restRoom02.image,
    alt: images.restRoom02.alt,
    list: "Shower cubicles",
  },
  {
    id: 4,
    img: images.restRoom03.image,
    alt: images.restRoom03.alt,
    list: "Toilets",
  },
  {
    id: 5,
    img: images.restRoom04.image,
    alt: images.restRoom04.alt,
    list: "Washbasins",
  },
  {
    id: 6,
    img: images.restRoom05.image,
    alt: images.restRoom05.alt,
    list: "Daily cleaning and sanitization by housekeeping staff",
    imgClass: "daily_img",
  },
  {
    id: 7,
    img: images.restRoom06.image,
    alt: images.restRoom06.alt,
    list: "24/7 water supply",
  },
  {
    id: 8,
    img: images.restRoom07.image,
    alt: images.restRoom07.alt,
    list: "Sufficient showers for residents",
  },
  {
    id: 9,
    img: images.restRoom08.image,
    alt: images.restRoom08.alt,
    list: "Regular maintenance for a hassle-free living experience",
  },
  {
    id: 10,
    img: images.restRoom09.image,
    alt: images.restRoom09.alt,
    list: "Sanitary bins for disposal of sanitary products",
  },
];
