import { accommodationURL, bookingURL } from "../helpers/paths";
import { bannerVideo, bannerVideoMb, images } from "../source";

// HOMEPAGE DATA

export const bannerData = [
  {
    id: 1,
    type: "video",
    video: bannerVideo,
    mbVideo: bannerVideoMb,
    banImg: images.banner01.image,
    alt: images.banner01.alt,
  },
  // {
  //   id: 2,
  //   type: "image",
  //   banImg: images.banner01.image,
  //   alt: images.banner01.alt,
  // },
  // {
  //   id: 3,
  //   type: "image",
  //   banImg: images.banner01.image,
  //   alt: images.banner01.alt,
  // },
  // {
  //   id: 4,
  //   type: "image",
  //   banImg: images.banner01.image,
  //   alt: images.banner01.alt,
  // },
];

export const uspData = [
  {
    id: 1,
    row: 1,
    img: images.why01.image,
    mbImg: images.mbWhy01.image,
    title: "Integrated Campus",
    description:
      "Aiming to cultivate well-rounded individuals who can think critically, adapt to new situations, and contribute positively to society.",
  },
  {
    id: 2,
    row: 1,
    img: images.why03.image,
    mbImg: images.mbWhy03.image,
    title: "All Round Development",
    description:
      "Encompassing the development of intellectual, emotional, social, physical, and moral faculties of students.",
  },
  {
    id: 3,
    row: 1,
    img: images.why02.image,
    mbImg: images.mbWhy02.image,
    title: "Safety and Security",
    description:
      "The well-being of our residents is our top priority. Our accommodation is equipped with the latest security features, ensuring a safe and secure environment for all.",
  },
  {
    id: 4,
    row: 1,
    img: images.why06.image,
    mbImg: images.mbWhy06.image,
    title: "Sense of Community and Events",
    description:
      "Providing individuals with a support network, a source of identity and belonging, and a shared platform for collaboration, growth, and collective action.",
  },
  // {
  //   id: 5,
  //   row: 2,
  //   img: images.why05.image,
  //   title: "Laundry Services",
  //   description:
  //     "Weekly Laundry services to keep your wardrobe fresh and clean without the hassle.",
  // },
  // {
  //   id: 6,
  //   row: 2,
  //   img: images.why04.image,
  //   title: "Exceptional Food Services",
  //   description:
  //     "Savor a variety of meals including vegetarian, non-vegetarian, and Jain options, served four times a day by Sodexho caterers.",
  // },
  // {
  //   id: 7,
  //   row: 2,
  //   img: images.why07.image,
  //   title: "Safety and Security",
  //   description:
  //     "A secure environment with a focus on student safety is our top priority.",
  // },
];

export const vidData = [
  {
    id: 1,
    row: 1,
    ytLink: "https://www.youtube.com/embed/SZ3t1snaf_w?si=YqqsgIiuQJ-bn3gb",
  },
  {
    id: 2,
    row: 1,
    ytLink: "https://www.youtube.com/embed/X03yit90PWU?si=aFsFCXYZOB1n15Ql",
  },
  {
    id: 3,
    row: 1,
    ytLink: "https://www.youtube.com/embed/J5CRlsVwleM?si=M3bLO5NS9QyGwQJ1",
  },
];

export const accommodationData = [
  {
    id: 1,
    heroImg: images.accommodation01.image,
    heroMbImg: images.accommodation01mb.image,
    alt: images.accommodation01.alt,
    title: "4 Share room",
    highlights: [
      {
        icon: images.acIcon.image,
        title:
          "Centrally air-conditioned rooms with in-room temperature control",
      },
      {
        icon: images.housekeepingIcon.image,
        title: "Pantry kitchenette area on every floor with all provisions",
      },
      {
        icon: images.medicineIcon.image,
        title: "Housekeeping services",
      },
      {
        icon: images.accessIcon.image,
        title: "Facial recognition to enter accommodation floors",
      },
      // {
      //   icon: images.medicineIcon.image,
      //   title: "Medical & General accident insurance",
      // },
    ],
    description:
      "Fee starting at <strong>Rs. 51,786/-</strong> (Per month all Inclusive)",
    ctaList: [
      {
        type: "internal",
        text: "Know more",
        url: accommodationURL,
      },
      {
        type: "external",
        text: "Book now",
        url: bookingURL,
      },
    ],
  },
  {
    id: 2,
    heroImg: images.accommodation02.image,
    heroMbImg: images.accommodation02mb.image,
    alt: images.accommodation02.alt,
    title: "3 Share room",
    highlights: [
      {
        icon: images.acIcon.image,
        title:
          "Centrally air-conditioned rooms with in-room temperature control",
      },
      {
        icon: images.housekeepingIcon.image,
        title: "Pantry kitchenette area on every floor with all provisions",
      },
      // },
      {
        icon: images.medicineIcon.image,
        title: "Housekeeping services",
      },
      {
        icon: images.accessIcon.image,
        title: "Facial recognition to enter accommodation floors",
      },
      // {
      //   icon: images.medicineIcon.image,
      //   title: "Medical & General accident insurance",
      // },
    ],
    description:
      "Fee starting at <strong>Rs. 57,666/-</strong> (Per month all Inclusive)",
    ctaList: [
      {
        type: "internal",
        text: "Know more",
        url: accommodationURL,
      },
      {
        type: "external",
        text: "Book now",
        url: bookingURL,
      },
    ],
  },
  {
    id: 3,
    heroImg: images.accommodation03.image,
    heroMbImg: images.accommodation03mb.image,
    alt: images.accommodation03.alt,
    title: "Twin Share room",
    highlights: [
      {
        icon: images.acIcon.image,
        title:
          "Centrally air-conditioned rooms with in-room temperature control",
      },
      {
        icon: images.housekeepingIcon.image,
        title: "Pantry kitchenette area on every floor with all provisions",
      },
      {
        icon: images.medicineIcon.image,
        title: "Housekeeping services",
      },
      {
        icon: images.accessIcon.image,
        title: "Facial recognition to enter accommodation floors",
      },
      // {
      //   icon: images.medicineIcon.image,
      //   title: "Medical & General accident insurance",
      // },
    ],
    description:
      "Fee starting at <strong>Rs. 69,426/-</strong> (Per month all Inclusive)",
    ctaList: [
      {
        type: "internal",
        text: "Know more",
        url: accommodationURL,
      },
      {
        type: "external",
        text: "Book now",
        url: bookingURL,
      },
    ],
  },
];

export const homeAmenitiesData = [
  {
    id: 1,
    row: 1,
    img: images.amenities01.image,
    title: "Sense of Community and Events",
    description:
      "Play a vital role in strengthening social ties, promoting civic pride, and enhancing the quality of life.",
  },
  {
    id: 2,
    row: 1,
    img: images.amenities02.image,
    title: "Medical Assistance",
    description: "Immediate medical attention is available on-premises for your health and well-being.",
  },
  {
    id: 3,
    row: 1,
    img: images.amenities03.image,
    title: "Accommodation",
    description: "Choose from 4-share, 3-share, or twin-share rooms, all maintained with the highest standards of cleanliness.",
  },
  {
    id: 4,
    row: 2,
    img: images.amenities04.image,
    title: "Gymnasium and Indoor Sports",
    description:
      "Maintain your fitness routine with access to our well-equipped gym. Unwind and have fun with our array of indoor sports and gaming options.",
  },
  {
    id: 5,
    row: 2,
    img: images.amenities05.image,
    title: "Exceptional Food Services",
    description: "Savor a variety of meals including vegetarian, non-vegetarian, and Jain options, served four times a day by Sodexho caterers.",
  },
  // {
  //   id: 6,
  //   row: 2,
  //   img: images.amenities05.image,
  //   title: "Indoor Sports & Gaming",
  //   description:
  //     "Unwind and have fun with our array of indoor sports and gaming options.",
  // },
];

export const termsData = [
  {
    tabName: "",
    data: [
      {
        title: "",
        description:
          "Using Atlas Student Hostel indicates that you accept these terms regardless of whether or not you choose to register with us. If you do not accept these terms, do not use Atlas Student Hostel.<br /> <br />This notice is issued by Atlas Student Hostel.",
      },
      // {
      //   title: "",
      //   description: "This notice is issued by Atlas Student Hostel.",
      // },
      {
        title: "1. INTRODUCTION",
        description:
          "You may access most areas of ATLAS Student Hostel without registering your details with us. Certain areas of ATLAS Student Home are only open to you if you register.<br /><br />By accessing any part of ATLAS Student Home, you shall be deemed to have accepted this legal notice in full. If you do not accept this legal notice in full, you must leave ATLAS Student Hostel immediately.<br /><br />The Company may revise this legal notice at any time by updating post. You should check ATLAS Student Hostel website from time to time to review the then current legal notice, because it is binding on you. Certain provisions of this legal notice may be superseded by expressly designated legal notices or terms located on particular pages at ATLAS Student Hostel.",
      },
      {
        title: "2. LICENCE",
        description:
          "You are permitted to use spaces from ATLAS Student Hostel for your own personal use on the following basis:<br /><br />No pictures or graphics of ATLAS Student Hostel are used for marketing or advertising purpose<br /><br />The Company’s copyright and trademark notices and this permission notice appear in all copies.<br /><br />Unless otherwise stated, the copyright and other intellectual property rights in all material on ATLAS Student Hostel (including without limitation photographs and graphical images) are owned by the Company or its licensors. For the purposes of this legal notice, any use of extracts from ATLAS Student Hostel other than in accordance with clause 2.1 above for any purpose is prohibited. If you breach any of the terms in this legal notice, your permission to use ATLAS Student Hostel automatically terminates and you must immediately destroy any pictures or graphics from ATLAS Student Hostel.<br /><br />Subject to clause 2.1, no part of ATLAS Student Hostel may be reproduced or stored in any other website or included in any public or private electronic retrieval system or service without the Company’s prior written permission.<br /><br />Any rights not expressly granted in these terms are reserved.",
      },
      {
        title: "3. SERVICE ACCESS",
        description:
          "While the Company endeavors to ensure that ATLAS Student Hostel is normally available 24 hours a day, the Company shall not be liable if for any reason ATLAS Student Hostel is unavailable due to any maintenance work.<br /><br />Access to ATLAS Student Hostel may be suspended temporarily and without notice in the case of system failure, maintenance or repair or for reasons beyond the Company’s control.",
      },
      {
        title: "4. VISITOR MATERIAL AND CONDUCT",
        description:
          "Other than personally identifiable information, which is covered under the Privacy Policy, any material you transmit or post to ATLAS Student Hostel shall be considered non-confidential and non-proprietary. The Company shall have no obligations with respect to such material. The Company and its designees shall be free to copy, disclose, distribute, incorporate and otherwise use such material and all data, images, sounds, text and other things embodied therein for any and all commercial or non-commercial purposes.<br /><br />You are prohibited from posting about transmitting to or from ATLAS Student Hostel any that is threatening, defamatory, obscene, indecent, seditious, offensive, pornographic, abusive, liable to incite racial hatred, discriminatory, menacing, scandalous, inflammatory, blasphemous, in breach of confidence, in breach of privacy or which may cause annoyance or inconvenience; for which you have not obtained all necessary licences and/or approvals;<br /><br />which is technically harmful (including, without limitation, computer viruses, logic bombs, Trojan horses, worms, harmful components, corrupted data or other malicious software or harmful data).<br /><br />You may not misuse the Student hostel<br /><br />The student shall fully cooperate with any law enforcement authorities or court order requesting or directing the Company to disclose the identity or locate anyone posting any material in breach of clauses above.<br /><br />Copy, reproduce, modify, distribute or publicly display any content (except for your information) from our space without the prior written consent or appropriate third party, as applicable;",
      },
      {
        title: "",
        description:
          "<strong>Consent to processing your data</strong><br /><br />An integral part of the ATLAS Student Hostel Platform entails the collection, processing, transmission and disclosure of Your Data by ATLAS Student Hostel and its affiliates, and you hereby specifically consent to:<br /><br />Atlas Student Home’s provision of Your Data to any Atlas Student Hostel Partner, VAS agencies, partner organizations, professional bodies, service providers to Atlas Student Hostel (including our accommodation providers and payment processors) as part of the provision of the Atlas Student Hostel Solution to you, and Governmental or Regulatory Authorities; communications from Atlas Student Hostel (including all communication channels including e-mail communications, business WhatsApp, iMessage & other marketing and informational tools) respecting the Atlas Student Hostel Solution, and Atlas Student Hostel platform products and services; and collection, use and other processing activities as set out in Atlas Student Hostel ’s Privacy Policy<br /><br />Your Personal Information will be handled in accordance with ATLAS Student Hostel ’s Privacy and Cookies Policy.<br /><br />Storage of Your Data in Other Jurisdictions You acknowledge that, due to the nature of the ATLAS Student Hostel being a facilitator, Your Data uploaded to the ATLAS Student Hostel Platform may be hosted on servers residing in jurisdictions other than India, over which ATLAS Student Hostel has no direct control. By using the ATLAS Student Hostel Solution, Your Data may become, during the period that Your Data is hosted on such servers, subject to the Applicable Laws of the jurisdiction in which such servers reside or to the terms of agreements respecting the hosting of data on such servers.",
      },
      {
        title: "5 MESSAGE BOARD",
        description:
          "You agree to use any message board in accordance with the following rules:<br /><br /><ul><li>Offensive or vulgar language or abusive or threatening behavior is prohibitedll contributions must be civil and in good taste.</li><li>You must not post any unlawful or objectionable content including but not limited to material which is sexist, racist, homophobic, obscene, pornographic or defamatory.</li><li>For your own personal safety and security you must not reveal any personal information about yourself or anyone else (for example telephone or mobile number, or home address) in postings.</li></ul>",
      },
      {
        title: "",
        description: `<strong>What Information Do We Collect About You?</strong><br /><br />We collect information about you when you register with us or make an enquiry about our accommodation. You may provide us with personal information when communicating with ATLAS Student Home to complete a registration form, submit your interest for a Property, sign up for additional services or if you contact us with a query or complaint.<br /><br />Hostel for Students may collect information when you order a product or service and you give us personal information such as your name, email address, address, telephone number, credit or debit card number, relevant bank details or other relevant information. When you provide us with details of credit, debit or bank account details in order to make payments to us, any such information will only be used for the specific purpose for which it is provided.<br /><br />We will collect information when you voluntarily complete customer surveys, provide feedback and participate in competitions. ATLAS Student Hostel will collect information about your preferences and by analysis of customer traffic. Website usage information is collected using cookies.<br /><br />ATLAS Student Hostel may also collect information that is classed as sensitive personal data, such as ethnicity, religion, sexuality and health. Such information will only be collected with your express consent.<br /><br />How Will We Use This Information About You?<br /><br />We collect information about you to process your application for a tenancy with us, manage your account and, if you agree, to email you about services we think may be of interest to you.
           <br /><br/>We use your information for the following purposes:<br /><br /><ul><li>To process your application for a tenancy;</li><li>To administer your tenancy;</li> <li>To communicate with you and respond to your requests;</li> <li>To process payments;</li> <li>To recover any unpaid sums due to us;</li> <li>To prevent and detect criminal activity, fraud and misuse of or damage to our property;</li> <li>To invite you to participate in our consumer satisfaction surveys or research groups;</li><li> Where we have your consent to do so, to contact you about other products and services that we think may be of interest to you.</li></ul>
          `,
      },
      // {
      //   title: "",
      //   description:
      //     "How Will We Use This Information About You?<br /><br />We collect information about you to process your application for a tenancy with us, manage your account and, if you agree, to email you about services we think may be of interest to you.",
      // },
      // {
      //   title: "",
      //   description:
      //     "We use your information for the following purposes:<br /><br /><ul><li>To process your application for a tenancy;</li><li>To administer your tenancy;</li> <li>To communicate with you and respond to your requests;</li> <li>To process payments;</li> <li>To recover any unpaid sums due to us;</li> <li>To prevent and detect criminal activity, fraud and misuse of or damage to our property;</li> <li>To invite you to participate in our consumer satisfaction surveys or research groups;</li><li> Where we have your consent to do so, to contact you about other products and services that we think may be of interest to you.</li></ul>",
      // },
    ],
  },
];

export const privacyData = [
  {
    heading: "",
    description:
      "ATLAS Student’s Hostel takes your data privacy seriously and has in place the following privacy statements in order to clearly set out how ATLAS Student’s Hostel collects and utilizes the information you provide to us.<br /><br />To ensure we're providing you with the best possible student experience, we collect your data whether you fill out a form on our website, submit an application for housing, contact us via phone, email, or in-person, or live in one of our student residences.<br /><br />Our privacy policies explain why we gather information, what we do with it, how we preserve it, and with whom we might share it. We have privacy statements for each step of our connection with you since we gather distinct kinds of data at each one. To make sure you understand how information about you will be used, kindly take the time to read our privacy policies.:<br /><br /><ul><li>Enquiring, registering and applying for a room</li><li>Tenancy Agreements & living with ATLAS Student’s Hostel</li><li>Guarantors</li></ul><br />Your privacy is important to us, and we take that responsibility seriously. This privacy statement describes how we use and disclose the personal data we have acquired about you as well as how you may exercise your rights in connection with that data. According to government policy, ATLAS Student's Hostel is registered and protects your data.",
  },
];

// ABOUT US DATA

export const aboutBannerData = [
  {
    id: 1,
    banImg: images.aboutBanner.image,
    mbBanImg: images.mbAboutBanner.image,
    alt: images.aboutBanner.alt,
  },
];

export const aboutValuesData = [
  {
    id: 1,
    img: images.value01.image,
    mbImg: images.mbValue01.image,
    title: "Holistic Learning",
    description:
      "Aiming to cultivate well-rounded individuals who can think critically, adapt to new situations, and contribute positively to society.",
  },
  {
    id: 2,
    img: images.value02.image,
    mbImg: images.mbValue02.image,
    title: "All Round Development",
    description:
      "Encompassing the development of intellectual, emotional, social, physical, and moral faculties of students.",
  },
  {
    id: 3,
    img: images.value03.image,
    mbImg: images.mbValue03.image,
    title: "Safety and Security",
    description:
      "The well-being of our residents is our top priority. Our accommodations are equipped with the latest security features, ensuring a safe and secure environment for all.",
  },
  {
    id: 4,
    img: images.value04.image,
    mbImg: images.mbValue04.image,
    title: "Sense of Community and Events",
    description:
      " Providing individuals with a support network, a source of identity and belonging, and a shared platform for collaboration, growth, and collective action.",
  },
];

// AMENITIES DATA

export const amenitiesBannerData = [
  {
    id: 1,
    banImg: images.amenityBanner.image,
    mbBanImg: images.mbAmenityBanner.image,
    alt: images.amenityBanner.alt,
  },
];

export const amenitiesData = [
  {
    column: 1,
    img: images.amenity01.image,
    mbImg: images.mbAmenity01.image,
    title: "Unwind Lounge",
    desc: "Relax and recharge in our Unwind Lounge, a serene space designed for contemplation and relaxation, embodying the essence of our integrated campus philosophy.",
  },
  // {
  //   column: 2,
  //   img: images.amenity02.image,
  //   mbImg: images.mbAmenity02.image,
  //   title: "Coffee Barista",
  //   desc: "Enjoy artisanal coffee crafted by our skilled baristas, a perfect spot for informal learning and networking within our integrated campus setting. (Coming Soon)",
  // },
  {
    column: 3,
    img: images.amenity03.image,
    mbImg: images.mbAmenity03.image,
    title: "Courtyard",
    desc: "Our Courtyard is the heart of campus life, designed to encourage outdoor learning and socializing in a beautifully landscaped environment.",
  },
  {
    column: 1,
    img: images.amenity04.image,
    mbImg: images.mbAmenity04.image,
    title: "Collaborative Corridor",
    desc: "Every corridor is an opportunity for interaction. Our Collaborative Corridors are designed to promote spontaneous discussions and idea exchanges.",
  },
  {
    column: 2,
    img: images.amenity05.image,
    mbImg: images.mbAmenity05.image,
    title: "Gymnasium & Fitness Studio",
    desc: "Stay fit and healthy with our fully equipped Gymnasium and Fitness Studio, supporting the physical well-being of our community members.",
  },
  {
    column: 3,
    img: images.amenity06.image,
    mbImg: images.mbAmenity06.image,
    title: "Multisport Turf",
    desc: "Engage in various sports activities on our Multisport Turf, fostering teamwork and physical fitness in an integrated campus environment.",
  },
  {
    column: 1,
    img: images.amenity07.image,
    mbImg: images.mbAmenity07.image,
    title: "Indoor Games",
    desc: "Our indoor games area offers a variety of games, promoting strategic thinking and relaxation in a community-focused setting.",
  },
  {
    column: 2,
    img: images.amenity08.image,
    mbImg: images.mbAmenity08.image,
    title: "Community Study & Group Study Space",
    desc: "Collaborate and learn in our Community Study spaces and Group Study Space, designed to support academic excellence and group projects.",
  },
  {
    column: 3,
    img: images.amenity09.image,
    mbImg: images.mbAmenity09.image,
    title: "Coworking Spaces",
    desc: "Embrace the future of work in our Coworking Spaces, equipped with all the amenities needed for productive collaboration and innovation.",
  },
  {
    column: 1,
    img: images.amenity10.image,
    mbImg: images.mbAmenity10.image,
    title: "Perimeter Security & Daily Housekeeping",
    desc: "Your safety and comfort are our top priorities. Enjoy peace of mind with our comprehensive Perimeter Security and Daily Housekeeping services.",
  },
  // {
  //   column: 2,
  //   img: images.amenity11.image,
  //   mbImg: images.mbAmenity11.image,
  //   title: "Laundry Service & Insurance",
  //   desc: "Experience convenience with our on-site Laundry Service provided to you weekly.",
  // },
  // {
  //   column: 2,
  //   img: images.amenity13.image,
  //   mbImg: images.mbAmenity13.image,
  //   title: "First Aid Supply",
  //   desc: "Quick access to First Aid Supplies across the campus ensures that minor health issues can be addressed promptly.",
  // },
  {
    column: 2,
    img: images.amenity13.image,
    mbImg: images.mbAmenity13.image,
    title: "Medical Team & Care Network",
    desc: "Our Medical Team and Care Network ensure that health and wellness support is always within reach, reflecting our commitment to a holistic integrated campus experience.",
  },
  {
    column: 2,
    img: images.amenity14.image,
    mbImg: images.mbAmenity14.image,
    title: "Breakout Zones",
    desc: "Escape the daily grind on every floor with our cozy breakout zones. Chill with friends, unwind, and recharge in comfort.",
  },
  {
    column: 3,
    img: images.amenity15.image,
    mbImg: images.mbAmenity15.image,
    title: "Community Living",
    desc: "A unique way of organizing residential spaces that prioritizes social connection, cooperation, and shared experiences and enriching the lives of residents.",
  },
  {
    column: 1,
    img: images.amenity16.image,
    mbImg: images.mbAmenity16.image,
    title: "Events",
    desc: "Our events serve various purposes, including promoting social interaction, fostering academic and personal growth, celebrating diversity, and enhancing the overall college experience.",
  },
  {
    column: 2,
    img: images.amenity17.image,
    mbImg: images.mbAmenity17.image,
    title: "Pantry Area",
    desc: "Pantry area on each floor to store your delicious food and beverages and have a quick snack.",
  },
  {
    column: 3,
    img: images.amenity18.image,
    mbImg: images.mbAmenity18.image,
    title: "Vending Machine",
    desc: "A quick and easy way to purchase snacks, beverages, and other items on-the-go.",
  },
];

// DINING US DATA

export const diningBannerData = [
  {
    id: 1,
    banImg: images.diningBanner.image,
    mbBanImg: images.mbDiningBanner.image,
    alt: images.diningBanner.alt,
  },
];

export const diningValuesData = [
  {
    id: 1,
    img: images.diningCard01.image,
    mbImg: images.mbDiningCard01.image,
    title: "Breakfast",
    description:
      "Hot snacks, Live omelet counter, fruits, cereal & hot beverages.",
  },
  {
    id: 2,
    img: images.diningCard02.image,
    mbImg: images.mbDiningCard02.image,
    title: "Lunch",
    description:
      "Customised menus based on Indian, Asian, Mexican & Continental cuisines.",
  },
  {
    id: 3,
    img: images.diningCard03.image,
    mbImg: images.mbDiningCard03.image,
    title: "High Tea & Snacks",
    description: "Evening snacks and beverages.",
  },
  {
    id: 4,
    img: images.diningCard04.image,
    mbImg: images.mbDiningCard04.image,
    title: "Dinner",
    description:
      "Customised menus based on Indian, Asian, Mexican & Continental cuisines.",
  },
];

// STUDENT LIFE DATA

export const studentLifeBannerData = [
  {
    id: 1,
    banImg: images.studentBanner.image,
    mbBanImg: images.mbStudentBanner.image,
    alt: images.studentBanner.alt,
  },
];

export const studentLifeData = [
  {
    img: images.morningWorkouts.image,
    mbImg: images.mbMorningWorkouts.image,
    title: "Morning Workouts",
    desc: "Our day begins with the sun peering through, beckoning us to the state-of-the-art fitness facilities. Here, students find their zen or pump up their energy levels, preparing themselves for the day ahead with morning workouts that invigorate both mind and body.",
  },
  {
    img: images.classroomMinute.image,
    mbImg: images.mbClassroomMinute.image,
    title: "Classroom in A Minute",
    desc: "With our integrated campus design, the transition from leisure to learning is seamless. Classrooms are just a minute away, embodying our commitment to accessibility and efficiency. This proximity ensures that students can make the most of their time, focusing on their studies without the hassle of long commutes.",
  },
  {
    img: images.breakfastDone.image,
    mbImg: images.mbBreakfastDone.image,
    title: "Breakfast done the Mumbai way",
    desc: "Breakfast at ATLAS is an experience in itself, offering a taste of Mumbai with a variety of local flavors. It’s more than just a meal; it’s a celebration of the city’s rich culinary heritage, ensuring students start their day nourished and ready to tackle whatever comes their way.",
  },
  {
    img: images.relaxingLunch.image,
    mbImg: images.mbRelaxingLunch.image,
    title: "Indoor Games",
    desc: "Our indoor games area offers a variety of games, promoting strategic thinking and relaxation in a community-focused setting.",
  },
  {
    img: images.studyTime.image,
    mbImg: images.mbStudyTime.image,
    title: "Study Time",
    desc: "Our well-equipped study zones are designed to foster an environment where focus and collaboration thrive. Whether it’s preparing for exams or working on group projects, students find the support and resources they need to excel.",
  },
  {
    img: images.matchTurf.image,
    mbImg: images.mbMatchTurf.image,
    title: "Cricket Turf",
    desc: "As the afternoon wanes, the turf comes alive with the spirit of competition and camaraderie. A friendly match of cricket not only celebrates our commitment to sports and wellness but also strengthens the bonds within our community.",
  },
  {
    img: images.EndingDay.image,
    mbImg: images.mbEndingDay.image,
    title: "Ending a Power-Packed Day",
    desc: "As night falls, students retire to their comfortable accommodations, reflecting on a day well spent. It’s a moment of gratitude and anticipation, recharging for another day filled with opportunities and adventures.",
  },
  {
    img: images.SplendidDinner.image,
    mbImg: images.mbSplendidDinner.image,
    title: "Splendid Meals 4 Times a Day",
    desc: "Indulge in a gastronomic journey with sumptuous meals meticulously crafted, nutritious and served four times daily, promising an exquisite dining experience that delights the senses and satisfies the palate.",
  },
  {
    img: images.weekendAdventures.image,
    mbImg: images.mbWeekendAdventures.image,
    title: "Weekend Adventures in Mumbai",
    desc: "Weekends are reserved for exploring the vibrant city of Mumbai, with its endless cultural, historical, and entertainment options. Our prime location makes it easy for students to embark on these adventures, discovering new facets of the city and themselves.",
  },
  {
    img: images.festivalCelebration.image,
    mbImg: images.mbFestivalCelebration.image,
    title: "Festival Celebrations",
    desc: "Featuring a diverse range of activities, performances, competitions, and social gatherings that bring together students, faculty, staff, alumni, and sometimes members of the local community.",
  },
  {
    img: images.movieNight.image,
    mbImg: images.mbMovieNight.image,
    title: "Movie Night",
    desc: "A fun and inclusive way for students to come together, de-stress, and enjoy a sense of community on campus.",
  },
];
